.small-text{
    font-size: xx-small;
}

.waiting{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    margin-right: auto;
    margin-left: auto;
}

.loading{
    position: absolute;
    width: 50%;
    top: 50%;
    margin: 0 auto;
    left: 45%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
